<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{ l('ViewDetails') }}</span>
            </div>
        </div>
        <a-config-provider :locale="zh_CN">
            <a-card style="border: 0px;" class="ant-card-body-par">
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns" :data-source="tableData" :rowKey="(record) => record.id"
                        :scroll="{ x: 800, y: 400 }" :pagination="false" size="small">
                    </a-table>
                </a-spin>
                <a-pagination class="pagination" :total="totalItems" v-model="pageNumber" showSizeChanger
                    :defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions" showQuickJumper
                    :showTotal="showTotalFun" @change="onChangePage" @showSizeChange="showSizeChange"
                    style="margin-bottom: -10px;" />
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {
    AuditTaskServiceProxy
} from '../../../../shared/service-proxies';
import {
    ModalHelper
} from '@/shared/helpers';
import moment from "moment";

let _this;
export default {
    name: 'OrderPicking',
    mixins: [ModalComponentBase],
    props: {
        auditID: [],
        isEdit: [],
        auditTaskId: [],
        auditPointId: [],
        auditItemId: []
    },
    components: {

    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
    },
    mounted() {
        this.getData();
    },
    watch: {
        auditID: function () {
            this.getData();
        },
        isEdit: function () {
            this.getData();
        },
        auditTaskId: function () {
            this.getData();
        },
        auditPointId: function () {
            this.getData();
        },
        auditItemId: function () {
            this.getData();
        },
    },
    data() {
        return {
            zh_CN,
            tableData: [],
            columns: [{
                title: this.l('Discoverer'),
                width: 90,
                dataIndex: 'discovererName',
                align: 'center',
                ellipsis: true,
            },
            {
                title: this.l('219'),
                width: 150,
                dataIndex: 'issueTypeListName',
                align: 'center',
            },
            {
                title: this.l('ModuleDes'),
                width: 200,
                dataIndex: 'description',
                align: 'left',
            },
            {
                title: this.l('Non-conformity'),
                width: 170,
                dataIndex: 'deviationTypeName',
                align: 'left',
            },
            {
                title: this.l('Correctivemeasures'),
                width: 150,
                dataIndex: 'improveNote',
                align: 'center',
            },
            {
                title: this.l('layoutuser.isresponsible'),
                width: 100,
                dataIndex: 'respName',
                align: 'center',
            },
            {
                title: this.l('Submissiontime'),
                width: 150,
                dataIndex: 'discoveryTime',
                align: 'center',
            },
            {
                title: this.l('audit.planstart'),
                width: 150,
                dataIndex: 'planStart',
                align: 'center',
            },
            {
                title: this.l('audit.planend'),
                width: 150,
                dataIndex: 'planEnd',
                align: 'center',
            },
            {
                title: this.l('audit.endtime'),
                width: 150,
                dataIndex: 'closeTime',
                align: 'center',
            },
            ],

            isLoading: false, //加载中
            filterText: '', //搜索
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ["10", "20", "50", "100", "500"],
            request: {
                maxResultCount: 20,
                skipCount: 0
            },
            isLook: false, //是否是查看
            AuditTaskId: undefined,
            AuditPointId: undefined,
            AuditItemId: undefined,
            idList: [],
        }
    },
    methods: {
        getData() {

            if (this.isEdit == false) {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getIssueByIssueId(
                    this.idList,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items
                    this.tableData.map(item => {
                        item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format('YYYY-MM-DD HH:mm') : "";
                        item.planStart = item.planStart ? moment(item.planStart).format('YYYY-MM-DD HH:mm') : "";
                        item.planEnd = item.planEnd ? moment(item.planEnd).format('YYYY-MM-DD HH:mm') : "";
                        item.closeTime = item.closeTime ? moment(item.closeTime).format('YYYY-MM-DD HH:mm') : "";
                    })
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            } else {
                this.isLoading = true;
                this.AuditTaskId = this.auditTaskId;
                this.AuditPointId = this.auditPointId;
                this.AuditItemId = this.auditItemId;
                console.log(this.AuditTaskId);
                console.log(this.AuditPointId);
                console.log(this.AuditItemId);
                this.AuditTaskServiceProxy.getIssueByIssueId(
                    this.idList,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items
                    this.tableData.map(item => {
                        item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format('YYYY-MM-DD HH:mm') : "";
                        item.planStart = item.planStart ? moment(item.planStart).format('YYYY-MM-DD HH:mm') : "";
                        item.planEnd = item.planEnd ? moment(item.planEnd).format('YYYY-MM-DD HH:mm') : "";
                        item.closeTime = item.closeTime ? moment(item.closeTime).format('YYYY-MM-DD HH:mm') : "";
                    })
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            }

        },
        //正常分页展示形式，显示（共有 {0} 条）
        showTotalFun() {
            // return this.l(
            //     "GridFooterDisplayText",
            //     this.totalItems
            // );
        },

        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.selectedRowKeys = []
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.selectedRowKeys = []
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
            this.getData();
        },
    }
}
</script>

<style>
.pagination {
    margin: 10px auto;
    text-align: right;
}

.ant-card-body-par>.ant-card-body {
    padding: 0px 0px 10px 0px;
}
</style>
