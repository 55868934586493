<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 250px;text-align: center;margin-right:10px;" />
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
					    :rowKey="(record)=>record.id" :scroll="{x:scroll_x,y:scroll_x}" :pagination="false"
					    size="small">
						<span slot="issueCount" slot-scope="text,record">
                            <a @click="view(record)">{{text}}</a>
                        </span>
					</a-table>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		ReportServiceProxy,LayoutOrgServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';
    import view from "./view/view.vue";
    import { ModalHelper } from '@/shared/helpers';

	let _this;
	export default {
		name: "Duplicate-issues",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
			this.getData();
		},
		data() {
			return {
				zh_CN,
				isLoading: true, //加载中
				startTime: undefined,
				endTime: undefined,
				nowTime: [],
				
				tableData: [],
				columns: [{
				    title: this.l('auditpoint'),
				    width: 300,
				    dataIndex: 'auditPointName',
				    align: 'center',
				},
				{
				    title: this.l('AuditItem'),
				    width: 500,
				    dataIndex: 'auditItemName',
				    align: 'left',
					ellipsis: true,
				},
				{
				    title: this.l('问题关闭数量'),
				    width: 200,
				    dataIndex: 'issueCount',
				    align: 'center',
					scopedSlots: { customRender: "issueCount" },
				},
				{},
				],
			}
		},
		computed: {
			
		},
		methods: {
			moment,
			view(record) {
				console.log(record);
                ModalHelper.create(view, {
                    idList: record.issueIdList
                }, { width: 1000 }).subscribe(res => {
                    console.log(res)
                    if (res) {
                        this.getData();
                    }
                    this.selectedRowKey = [];
                })
            },
			function() {
				// this.yearDate = moment(new Date());
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startTime = moment(startTime);
				this.endTime = moment(endTime);
			},
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getSixMonthRepeatIssue(
					this.startTime,
					this.endTime
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.tableData = res;
				})
			},
			
			//选择日期
			DiscoverytimeChange(date, dateString) {
				this.startTime = date[0];
				this.endTime = date[1];
				this.getData();
			},
		}
	}
</script>

<style>
</style>
